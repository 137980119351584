<template>
  <div class="container">
    <div
      class="
        card
        voicemed-card voicemed-card-centered-medium
        transition-to-survey-bg
      "
    >
      <div class="container-center">
        <div class="card-top card-content record-info-card has-text-centered">
          <div class="has-text-centered mb-4">
            <img
              src="@/assets/media/gif/complete.gif"
              height="100"
              class="small-img"
              alt=""
            />
          </div>
          <h2 class="has-text-primary congrats-text mb-2">
            {{ exclamationMessage }}
          </h2>
          <p class="mb-2" v-html="leadMessage"></p>

          <p>{{ infoMessage }}</p>
        </div>
        <div class="card-bottom bottom w-full white-blur">
          <footer class="card-footer">
            <div class="card-footer-item">
              <b-button
                v-if="!hideEmailButton"
                size="is-large"
                class="button is-primary voicemed-button"
                @click="next()"
              >
                {{ stayTunedButtonLabel }}
              </b-button>
              <a
                @click="logout()"
                class="vmed-text-gradient mt-3 has-text-centered bottom-notice"
                ><strong>{{ exitLabel }}</strong></a
              >
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ClinicalTrialThank",
  props: {
    hideEmailButton: { default: false, type: Boolean },
    exclamationMessage: { default: "Thank you for your time !", type: String },
    leadMessage: {
      default:
        "<strong>VoiceMed’s Team</strong> are lucky to have you onboard.",
      type: String
    },
    infoMessage: {
      default: "Take care of yourself and your family 😷",
      type: String
    },
    stayTunedButtonLabel: { default: "I want to stay tuned", type: String },
    exitLabel: {
      default: "No thanks, (you can close this window)",
      type: String
    }
  },
  data: () => ({
    isConsentGiven: false
  }),
  methods: {
    next() {
      this.$emit("next");
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace(`/${this.$store.state.locale}`).catch(() => {});
    }
  },
  computed: {
    ...mapState(["locale"])
  }
};
</script>
<style scoped>
.medium-img {
  max-height: 400px;
}

.small-img {
  max-height: 100px;
}

.image-div {
  min-height: 150px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #a4e064 !important;
}

.card-footer-item {
  flex-direction: column;
}

@media screen and (max-width: 576px) {
  .transition-to-survey-bg {
    background: url("/images/saly.png") center bottom no-repeat;
  }
  .white-blur {
    background: rgb(255, 255, 255);
    background: rgba(252, 252, 252, 0.8);
  }
  .card-top {
    justify-content: start;
  }
  .text-black {
    color: #3d3d3d;
  }
  .container-center {
    height: 85vh;
  }
}
/* .card-content  */
</style>
