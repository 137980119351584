<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 bg-lg-voicemed">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-reversed.svg"
        alt=""
        srcset=""
      />
    </div>
    <div class="main columns has-text-centered" v-if="currentPage == 0">
      <RecordingGuide
        @next="moveToPage(currentPage + 1)"
        :title="$t('asl.recordingGuide.title')"
        :lead="$t('asl.recordingGuide.lead')"
        :stepTitle="$t('asl.recordingGuide.stepTitle')"
        :instructions="$t('asl.recordingGuide.instructions')"
        :readyButton="
          isMobileScreen() ? $t('buttons.watchTutorial') : $t('buttons.letsGo')
        "
        :showVideo="true"
        :hideAnimation="true"
      ></RecordingGuide>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 1">
      <CoughRecording
        :title="$t('asl.coughRecord.title')"
        :timerInstruction="$t('asl.coughRecord.timerInstruction')"
        :recordingTitle="$t('asl.coughRecord.recordingTitle')"
        :recordingSubtitle="$t('asl.coughRecord.recordingSubtitle')"
        :resultTitle="$t('asl.coughRecord.resultTitle')"
        :resultSubtitle="$t('asl.coughRecord.resultSubtitle')"
        :pageLabel="$t('asl.coughRecord.pageLabel', { step: 1, total: 3 })"
        @output="setAudio($event, 'cough')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'cough')"
        :showVideo="true"
        :hideAnimation="true"
      ></CoughRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 2">
      <BreathRecording
        :title="$t('asl.breathRecord.title')"
        :timerInstruction="$t('asl.breathRecord.timerInstruction')"
        :recordingTitle="$t('asl.breathRecord.recordingTitle')"
        :recordingSubtitle="$t('asl.breathRecord.recordingSubtitle')"
        :resultTitle="$t('asl.breathRecord.resultTitle')"
        :resultSubtitle="$t('asl.breathRecord.resultSubtitle')"
        :pageLabel="$t('asl.breathRecord.pageLabel', { step: 2, total: 3 })"
        @output="setAudio($event, 'breath')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'breath')"
        :showVideo="true"
        :hideAnimation="true"
      ></BreathRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 3">
      <SpeechRecording
        :title="$t('asl.speechRecord.title')"
        :timerInstruction="$t('asl.speechRecord.timerInstruction')"
        :recordingTitle="$t('asl.speechRecord.recordingTitle')"
        :recordingSubtitle="$t('asl.speechRecord.recordingSubtitle')"
        :resultTitle="$t('asl.speechRecord.resultTitle')"
        :resultSubtitle="$t('asl.speechRecord.resultSubtitle')"
        :pageLabel="$t('asl.speechRecord.pageLabel', { step: 3, total: 3 })"
        @output="setAudio($event, 'speech')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'speech')"
        :showVideo="true"
        :hideAnimation="true"
      ></SpeechRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 4">
      <transition-to-survey
        :headerOne="$t('asl.transitionToSurvey.headerOne')"
        :headerTwo="$t('asl.transitionToSurvey.headerTwo')"
        :infoMessage="$t('asl.transitionToSurvey.infoMessage')"
        :nextButtonLabel="$t('asl.transitionToSurvey.nextButtonLabel')"
        @next="moveToPage(currentPage + 1)"
      >
      </transition-to-survey>
    </div>

    <div class="main columns has-text-left" v-if="currentPage == 5">
      <div class="container">
        <question-section-v-1
          :questions="questions"
          :itemsPerPage="1"
          :setCurrentPage="currentQuestionPage"
          :questionTranslations="$t('asl.questions', { returnObjects: true })"
          @next="handleQuestionNextPage($event)"
          @previous="handleQuestionPrevPage($event)"
          @end="nextPageSection()"
        ></question-section-v-1>
      </div>
    </div>

    <div class="main columns" v-if="currentPage == 6">
      <clinical-trial-thank
        :exclamationMessage="$t('asl.thankYou.exclamationMessage')"
        :leadMessage="$t('asl.thankYou.leadMessage')"
        :infoMessage="$t('asl.thankYou.infoMessage')"
        :stayTunedButtonLabel="$t('asl.thankYou.stayTunedButtonLabel')"
        :exitLabel="$t('asl.thankYou.exitLabel')"
        @next="moveToPage(currentPage + 1)"
      ></clinical-trial-thank>
    </div>
    <div class="main columns" v-if="currentPage == 7">
      <ASLEmailGathering
        :header="$t('asl.emailSection.header')"
        :lead="$t('asl.emailSection.lead')"
        :emailFormLabel="$t('asl.emailSection.emailFormLabel')"
        :sendMailLabel="$t('asl.emailSection.sendMailButtonLabel')"
        @done="submitGuestEmail($event)"
      ></ASLEmailGathering>
    </div>
    <div class="main columns" v-if="currentPage == 8">
      <ASLEmailSent
        :header="$t('asl.emailSentSection.header')"
        :lead="$t('asl.emailSentSection.lead')"
        :email="emailAfterSurvey"
        :closeWindowLabel="$t('asl.emailSentSection.closeWindowLabel')"
        @done="logout()"
      >
      </ASLEmailSent>
    </div>
  </div>
</template>
<script>
import CoughRecording from "@/core/components/ui/audio-ui/CoughRecording";
import BreathRecording from "@/core/components/ui/audio-ui/BreathRecording";
import SpeechRecording from "@/core/components/ui/audio-ui/SpeechRecording";
import RecordingGuide from "@/core/components/ui/guidelines-ui/RecordingGuide";
import QuestionSectionV1 from "@/core/components/ui/questions-ui/vmd-trial-v1/QuestionSectionV1.vue";
import ASLEmailGathering from "./partials/ASLEmailGathering.vue";
import surveyService from "@/core/services/survey.service";
import TransitionToSurvey from "@/core/components/ui/extra-ui/TransitionToSurvey.vue";
import { mapActions, mapGetters } from "vuex";
import ClinicalTrialThank from "../../../core/components/ui/extra-ui/ClinicalTrialThank.vue";
import ASLEmailSent from "./partials/ASLEmailSent.vue";

export default {
  name: "ASLTrial",
  components: {
    CoughRecording,
    BreathRecording,
    SpeechRecording,
    RecordingGuide,
    ASLEmailGathering,
    QuestionSectionV1,
    TransitionToSurvey,
    ClinicalTrialThank,
    ASLEmailSent,
  },
  computed: {
    ...mapGetters(["getSurveyQuestions", "getSurveyCompletionPercentage"]),
  },
  created() {
    this.changeTrialSurveyQuestionsAdvanced().then((resp) => {
      this.questions = resp.questions;
    });

    // Set Last Visited Page and section
    surveyService.getTrialPageStats().then((resp) => {
      const data = resp.data.data;
      this.currentQuestionPage = Number(data.last_visited_section);
      this.currentPage = Number(data.last_visited_page);
    });
    this.questions = this.$t("asl.questions", "en");
    this.changeSurveyQuestions(this.questions);
  },
  data: () => ({
    isLoading: false,
    currentPage: 0,
    currentQuestionPage: 0,
    isConsentGiven: false,
    isRecording: false,
    pages: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    questions: [],
    emailAfterSurvey: null,
    audio: {
      breath: null,
      speech: null,
      cough: null,
    },
  }),
  methods: {
    ...mapActions([
      "postSurvey",
      "changeTrialSurveyQuestionsAdvanced",
      "changeSurveyQuestions",
    ]),
    isMobileScreen() {
      return window.screen.width <= 576;
    },
    moveToPage(num) {
      this.currentPage = num;
      this.$router
        .replace({
          name: "ASLTrial",
          // query: { section: this.currentQuestionPage, page: num }
        })
        .catch(() => {});
      window.scrollTo({
        top: 5,
        behavior: "smooth",
      });
    },
    handleQuestionNextPage($event) {
      this.currentQuestionPage = $event.currentPage;
      this.$router
        .replace({
          name: "ASLTrial",
          // query: { section: this.currentQuestionPage, page: this.currentPage }
        })
        .catch(() => {});
      this.submitQuestions();
    },
    handleQuestionPrevPage($event) {
      this.currentQuestionPage = $event.currentPage;
      this.$router
        .replace({
          name: "ASLTrial",
          // query: { section: this.currentQuestionPage, page: this.currentPage }
        })
        .catch(() => {});
    },
    setAudio(value, recordType) {
      this.audio[recordType] = value;
    },
    submitQuestions() {
      const payload = {
        title: "ASL X VOICEMED COVID-19 survery FOR CLINICAL TRIALS",
        description: "ASL X VOICEMED COVID 19 Survey for CLINICAL TRIALS",
        label: "COVID_19",
        questions: this.getSurveyQuestions,
        version: 3.2,
        completion_percentage: this.getSurveyCompletionPercentage,
        last_visited_page: this.currentQuestionPage,
        language: "en",
      };
      surveyService
        .postTrialSurvey(payload, {
          last_visited_page: this.currentPage,
          last_visited_section: this.currentQuestionPage,
        })
        .then(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    moveToNextRecord(value, recordType) {
      this.submitSample(recordType).then(() => true);
      this.moveToPage(value);
    },
    // eslint-disable-next-line no-unused-vars
    submitSample(recordType) {
      return surveyService.postTrialSample(
        "COVID_19",
        recordType,
        this.audio[recordType],
        {
          last_visited_page: this.currentPage,
          last_visited_section: this.currentQuestionPage,
        }
      );
    },
    submitGuestEmail(email) {
      this.emailAfterSurvey = email;
      surveyService.postGuestEmail(email).then(() => true);
      this.moveToPage(this.currentPage + 1);
    },
    nextPageSection() {
      this.moveToPage(this.currentPage + 1);
      this.submitQuestions();
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace(`/${this.$store.state.locale}`).catch(() => {});
    },
  },
};
</script>
<style scoped></style>
