<template>
  <div class="container">
    <div class="card voicemed-card voicemed-card-centered-medium">
      <div class="container-center">
        <div class="card-top card-content record-info-card has-text-centered">
          <h2 class="is-size-4 question-title has-text-weight-bold py-3">
            {{ header }}
          </h2>
          <br />
          <p class="py-3">
            {{ lead }}
          </p>
          <div class="voicemed-field-wrapper my-2">
            <b-field :label="emailFormLabel" :label-position="'inside'">
              <b-input
                class="voicemed-input-field"
                type="email"
                size="is-medium"
                v-model="guestEmail"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="card-bottom bottom w-full">
          <footer class="card-footer">
            <div class="card-footer-item">
              <b-button
                size="is-large"
                class="button is-primary voicemed-button"
                :disabled="!checkEmailValid()"
                @click="done()"
              >
                {{ sendMailLabel }}
              </b-button>
            </div>
          </footer>
        </div>
      </div>
    </div>

    <!-- <section class="has-text-centered">
          <b-button
            @click.native="done()"
            size="is-large"
            class="button is-primary"
            :disabled="!isConsentGiven"
            >{{ $t("survey.buttons.continue") }}</b-button
          >
        </section> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ASLEmailGathering",
  props: {
    header: {
      default: "VoiceMed is making progress each day to improve self-screening",
      type: String
    },
    lead: {
      default:
        "To stay informed of future diseases that we will be able to detect, just enter your email.",
      type: String
    },
    emailFormLabel: { default: "Your email", type: String },
    sendMailLabel: { default: "Send my email", type: String }
  },
  data: () => ({
    isConsentGiven: false,
    guestEmail: null
  }),
  methods: {
    done() {
      this.$emit("done", this.guestEmail);
    },
    checkEmailValid() {
      const regex = new RegExp(
        /* eslint-disable no-useless-escape */
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      return regex.test(this.guestEmail);
    }
  },
  computed: {
    ...mapState(["locale"])
  }
};
</script>
<style scoped>
@media all and (max-height: 625px) {
  .card-bottom {
    position: relative;
    margin-top: 5px;
    bottom: 0;
  }
  .container-center {
    height: 100%;
  }
}
</style>
