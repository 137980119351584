<template>
  <div class="container">
    <section
      class="card voicemed-card voicemed-card-centered-medium"
      :class="{ 'is-standalone-screen': isStandaloneDisplay }"
    >
      <div class="card-content record-info-card">
        <div class="top-content py-2 px-1">
          <h1 class="title voicemed-card-title">
            {{ title }}
          </h1>
          <p>
            {{ lead }}
          </p>
        </div>
        <div class="bottom-content py-1 px-1">
          <h3 class="title voicemed-card-title">
            {{ stepTitle }}
          </h3>
          <ul class="has-text-left voicemed-ul">
            <li
              v-for="(instruction, index) in instructions"
              :key="index"
              v-html="instruction"
            ></li>
            <!-- <li>
                  Check that the microphone is at an arm’s length from your mouth
                </li>
                <li>
                  Avoid sharing  personal information during the recording process
                </li> -->
          </ul>
        </div>
      </div>
      <footer class="card-footer w-full">
        <button class="button is-primary voicemed-button" @click="next()">
          {{ readyButton }}
        </button>
      </footer>
    </section>
  </div>
</template>
<script>
import { displayMethods } from "@/mixins/display";
export default {
  name: "RecordingGuide",
  mixins: [displayMethods],
  props: {
    instructions: {
      type: Array,
      default: () => [
        // TODO translation
        "Move to a quite place, <b>without background noise</b>",
        "Check that the microphone is at an arm’s length from your mouth",
        "Avoid sharing  personal information during the recording process",
      ],
    },
    title: { type: String, default: "It’s time to record your voice" },
    lead: { type: String, default: "Don’t worry we’ll guide you 😉" },
    stepTitle: { type: String, default: "3 simple rules" },
    readyButton: { type: String, default: "Lets go" },
  },
  methods: {
    next() {
      try {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            if (stream) this.$emit("next");
          })
          .catch(() => {
            this.alertAudioPermissionError();
          });
      } catch (error) {
        this.alertAudioPermissionError();
      }
    },
    alertAudioPermissionError() {
      this.$buefy.dialog.alert({
        title: "Audio Permission",
        message:
          "Permisson not granted to record audio, sorry you <b>cannot proceed</b>",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: auto;

  @media screen and (max-width: 576px) {
    .card-content {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
    .card-footer {
      padding: 1rem 1.8rem;
    }
  }

  @media screen and (max-width: 576px) and (min-height: 576px) {
    // min-height: calc(100vh - 160px);
    .card-footer {
      position: fixed;
      background: #fff;
      bottom: 0;
      left: 0;
      z-index: 99999;
    }
  }

  @media screen and (min-width: 768px) {
    .card-footer {
      padding: 1.5rem;
      max-width: 450px;
      margin: auto;
    }
  }
  .card-footer {
    display: flex;
    justify-content: center;
  }
  &.is-standalone-screen {
    @media screen and (max-width: 576px) and (min-height: 576px) {
      // min-height: calc(100vh - 160px);
      .card-footer {
        position: fixed;
        background: #fff;
        bottom: 20px !important;
        left: 0;
        z-index: 99999;
      }
    }
  }
}
</style>
