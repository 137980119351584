export const displayMethods = {
  computed: {
    isStandaloneDisplay() {
      let displayMode = "browser";
      const mqStandAlone = "(display-mode: standalone)";
      if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = "standalone";
      }
      return displayMode == "standalone";
    },
  },
};
