<template>
  <div class="container">
    <div
      class="card voicemed-card voicemed-card-centered-medium transition-to-survey-bg"
    >
      <div class="container-center">
        <div class="card-top card-content record-info-card has-text-centered">
          <h2
            class="is-size-4 question-title has-text-weight-bold"
            v-html="headerTwo"
          ></h2>
          <br />
          <p>
            {{ infoMessage }}
          </p>
        </div>
        <div class="card-bottom bottom w-full">
          <footer class="card-footer">
            <div class="card-footer-item">
              <b-button
                size="is-large"
                class="button is-primary voicemed-button"
                @click="next()"
              >
                {{ nextButtonLabel }}
              </b-button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransitionToSurvey",
  props: {
    headerOne: { default: "Thank you for sharing your voice", type: String },
    headerTwo: {
      default:
        "<strong>We need you 3 more minutes</strong> to help us in our research.",
      type: String,
    },
    infoMessage: {
      default: "Please answer this survey before leaving the application 🙏",
      type: String,
    },
    nextButtonLabel: { default: "Let's start the survey", type: String },
  },
  data: () => ({
    isConsentGiven: false,
  }),
  methods: {
    next() {
      this.$emit("next");
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 576px) {
  .transition-to-survey-bg {
    background: url("/images/transition-to-survey.png") left 33vh no-repeat;
  }
  .card-top {
    justify-content: start;
  }
}
/* .card-content  */
</style>
