<template>
  <div class="container">
    <div class="card voicemed-card voicemed-card-centered-medium">
      <div class="container-center">
        <div class="card-top card-content record-info-card has-text-centered">
          <h2 class="is-size-4 question-title has-text-weight-bold py-3">
            {{ header }}
          </h2>
          <br />
          <p class="py-3">
            {{ lead }}
          </p>
          <p class="has-text-centered">
            <strong>{{ email }}</strong>
          </p>
        </div>
        <div class="card-bottom bottom w-full">
          <footer class="card-footer">
            <div class="card-footer-item has-text-centered">
              <a
                @click="done()"
                class="vmed-text-gradient mt-3 has-text-centered bottom-notice"
                ><strong>{{ closeWindowLabel }}</strong></a
              >
            </div>
          </footer>
        </div>
      </div>
    </div>

    <!-- <section class="has-text-centered">
          <b-button
            @click.native="done()"
            size="is-large"
            class="button is-primary"
            :disabled="!isConsentGiven"
            >{{ $t("survey.buttons.continue") }}</b-button
          >
        </section> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "AslEmailSent",
  props: {
    header: { default: "Thank you for your interest", type: String },
    lead: {
      default:
        "We will keep you informed on the progress of the study and send you updates at :",
      type: String
    },
    email: { default: null, type: String },
    closeWindowLabel: { default: "You can close this window", type: String }
  },
  watch: {
    email: function(currentValue) {
      this.email = currentValue;
    }
  },
  data: () => ({
    isConsentGiven: false
  }),
  methods: {
    done() {
      this.$emit("done");
    }
  },
  computed: {
    ...mapState(["locale"])
  }
};
</script>
<style scoped>
.card-footer-item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
